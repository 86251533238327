import type { TFunction } from 'i18next'
import { Rank } from './fitnessTest'
import { allTestKeys } from './index'

export const elementaryTestMale = (t: TFunction<'translation', undefined>) => [
  {
    score: '1',
    grip: t('以下', { v: '4' }),
    sitUps: t('以下', { v: '2' }),
    bending: t('以下', { v: '14' }),
    sideJump: t('以下', { v: '17' }),
    enduranceRun: t('以上', { v: '9’21”' }),
    shuttleRun: t('以下', { v: '7' }),
    sprintRun: t('以上', { v: '13.1' }),
    standingJump: t('以下', { v: '92' }),
    handballThrow: t('以下', { v: '4' }),
  },
  {
    score: '2',
    grip: '5~6',
    sitUps: '3~5',
    bending: '15~18',
    sideJump: '18~21',
    enduranceRun: '8’20”~9’20”',
    shuttleRun: '8~9',
    sprintRun: '12.3~13.0',
    standingJump: '93~104',
    handballThrow: '5~6',
  },
  {
    score: '3',
    grip: '7~8',
    sitUps: '6~8',
    bending: '19~22',
    sideJump: '22~25',
    enduranceRun: '7’31”~8’19”',
    shuttleRun: '10~14',
    sprintRun: '11.5~12.2',
    standingJump: '105~116',
    handballThrow: '7~9',
  },
  {
    score: '4',
    grip: '9~10',
    sitUps: '9~11',
    bending: '23~26',
    sideJump: '26~29',
    enduranceRun: '6’51”~7’30”',
    shuttleRun: '15~22',
    sprintRun: '10.711.4',
    standingJump: '117~129',
    handballThrow: '10~12',
  },
  {
    score: '5',
    grip: '11~13',
    sitUps: '12~14',
    bending: '27~29',
    sideJump: '30~33',
    enduranceRun: '6’23”~6’50”',
    shuttleRun: '23~32',
    sprintRun: '10.0~10.6',
    standingJump: '130~142',
    handballThrow: '13~17',
  },
  {
    score: '6',
    grip: '14~16',
    sitUps: '15~17',
    bending: '30~33',
    sideJump: '34~37',
    enduranceRun: '5’56”~6’22”',
    shuttleRun: '33~44',
    sprintRun: '9.4~9.9',
    standingJump: '143~155',
    handballThrow: '18~23',
  },
  {
    score: '7',
    grip: '17~19',
    sitUps: '18~19',
    bending: '34~37',
    sideJump: '38~41',
    enduranceRun: '5’34”~5’55”',
    shuttleRun: '45~56',
    sprintRun: '8.9~9.3',
    standingJump: '156~167',
    handballThrow: '24~29',
  },
  {
    score: '8',
    grip: '20~22',
    sitUps: '20~22',
    bending: '38~42',
    sideJump: '42~45',
    enduranceRun: '5’17”~5’33”',
    shuttleRun: '57~68',
    sprintRun: '8.5~8.8',
    standingJump: '168~179',
    handballThrow: '30~34',
  },
  {
    score: '9',
    grip: '23~25',
    sitUps: '23~25',
    bending: '43~48',
    sideJump: '46~49',
    enduranceRun: '5’00”~5’16”',
    shuttleRun: '69~79',
    sprintRun: '8.1~8.4',
    standingJump: '180~191',
    handballThrow: '35~39',
  },
  {
    score: '10',
    grip: t('以上', { v: '26' }),
    sitUps: t('以上', { v: '26' }),
    bending: t('以上', { v: '49' }),
    sideJump: t('以上', { v: '50' }),
    enduranceRun: t('以下', { v: '4’59”' }),
    shuttleRun: t('以上', { v: '80' }),
    sprintRun: t('以下', { v: '8.0' }),
    standingJump: t('以上', { v: '192' }),
    handballThrow: t('以上', { v: '40' }),
  },
]

export const elementaryTestFemale = (
  t: TFunction<'translation', undefined>,
) => [
  {
    score: '1',
    grip: t('以下', { v: '3kg' }),
    sitUps: t('以下', { v: '2' }),
    bending: t('以下', { v: '17' }),
    sideJump: t('以下', { v: '16' }),
    enduranceRun: t('以上', { v: '6’58”' }),
    shuttleRun: t('以下', { v: '7' }),
    sprintRun: t('以上', { v: '13.3' }),
    standingJump: t('以下', { v: '84' }),
    handballThrow: t('以下', { v: '3' }),
  },
  {
    score: '2',
    grip: '4~6',
    sitUps: '3~5',
    bending: '18~20',
    sideJump: '17~20',
    enduranceRun: '6’15”~6’57”',
    shuttleRun: '8~9',
    sprintRun: '12.5~13.2',
    standingJump: '85~97',
    handballThrow: '4',
  },
  {
    score: '3',
    grip: '7~8',
    sitUps: '6~8',
    bending: '21~24',
    sideJump: '21~24',
    enduranceRun: '5’43”~6’14”',
    shuttleRun: '10~13',
    sprintRun: '11.7~12.4',
    standingJump: '98~108',
    handballThrow: '5',
  },
  {
    score: '4',
    grip: '9~10',
    sitUps: '9~11',
    bending: '25~28',
    sideJump: '25~27',
    enduranceRun: '5’19”~5’42”',
    shuttleRun: '14~18',
    sprintRun: '11.0~11.6',
    standingJump: '109~120',
    handballThrow: '6~7',
  },
  {
    score: '5',
    grip: '11~12',
    sitUps: '12~13',
    bending: '29~32',
    sideJump: '28~31',
    enduranceRun: '4’57”~5’18”',
    shuttleRun: '19~25',
    sprintRun: '10.3~10.9',
    standingJump: '121~133',
    handballThrow: '8~10',
  },
  {
    score: '6',
    grip: '13~15',
    sitUps: '14~15',
    bending: '33~36',
    sideJump: '32~35',
    enduranceRun: '4’38”~4’56”',
    shuttleRun: '26~34',
    sprintRun: '9.7~10.2',
    standingJump: '134~146',
    handballThrow: '11~13',
  },
  {
    score: '7',
    grip: '16~18',
    sitUps: '16~17',
    bending: '37~40',
    sideJump: '36~39',
    enduranceRun: '4’20”~4’37”',
    shuttleRun: '35~43',
    sprintRun: '9.2~9.6',
    standingJump: '147~159',
    handballThrow: '14~16',
  },
  {
    score: '8',
    grip: '19~21',
    sitUps: '18~19',
    bending: '41~45',
    sideJump: '40~42',
    enduranceRun: '4’03”~4’19”',
    shuttleRun: '44~53',
    sprintRun: '8.8~9.1',
    standingJump: '160~169',
    handballThrow: '17~20',
  },
  {
    score: '9',
    grip: '22~24',
    sitUps: '20~22',
    bending: '46~51',
    sideJump: '43~46',
    enduranceRun: '3’50”~4’02”',
    shuttleRun: '54~63',
    sprintRun: '8.4~8.7',
    standingJump: '170~180',
    handballThrow: '21~24',
  },
  {
    score: '10',
    grip: t('以上', { v: '25' }),
    sitUps: t('以上', { v: '23' }),
    bending: t('以上', { v: '52' }),
    sideJump: t('以上', { v: '47' }),
    enduranceRun: t('以下', { v: '3’49”' }),
    shuttleRun: t('以上', { v: '64' }),
    sprintRun: t('以下', { v: '8.3' }),
    standingJump: t('以上', { v: '181' }),
    handballThrow: t('以上', { v: '25' }),
  },
]

export const computeTotalPointIfHasEndurance = (
  baseTotalPoint: number,
  enduranceRun: number,
  shuttleRun: number,
  hasEnduranceRun: boolean,
): number => {
  if (hasEnduranceRun && enduranceRun > shuttleRun) {
    return baseTotalPoint + enduranceRun
  }

  return baseTotalPoint + shuttleRun
}

export type TEachTestRes =
  | {
      studentId?: string
      points?: number | null
      value?: null | number
      nextPoints?: number | null
      rating: number
      isNotMeasurable: boolean
      memo: null | string | undefined
      runningTime?: number | null
      runningTimeSeconds?: number | null
      sitUps?: number | null
    }
  | null
  | undefined

export type TTestResultWithPoint = Record<
  (typeof allTestKeys)[number] | 'sizeTest',
  TEachTestRes
> & {
  points?: number | null
  rank: Rank | ''
  nextPoints: number | undefined
  age: number
  rating?: number
  sizeTest?: { height?: null | number; weight?: number | null } | null
}

export const computeTotalPoint = (
  hasEnduranceRun: boolean,
  result: TTestResultWithPoint,
): number | undefined => {
  const grip = result.grip?.points ?? 0
  const bending = result.bending?.points ?? 0
  const handballThrow = result.handballThrow?.points ?? 0
  const shuttleRun = result.shuttleRun?.points ?? 0
  const sideJump = result.sideJump?.points ?? 0
  const sitUps = result.sitUps?.points ?? 0
  const sprintRun = result.sprintRun?.points ?? 0
  const standingJump = result.standingJump?.points ?? 0
  const enduranceRun = result.enduranceRun?.points ?? 0

  const baseTotalPoint =
    grip +
    bending +
    handballThrow +
    sideJump +
    sitUps +
    sprintRun +
    standingJump

  const totalPoint = computeTotalPointIfHasEndurance(
    baseTotalPoint,
    enduranceRun,
    shuttleRun,
    hasEnduranceRun,
  )

  return totalPoint === 0 ? undefined : totalPoint
}
