import { Form, InputNumber, Row } from 'antd'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TestSubjectKey } from '~/types/api/alpha/test-key'
import { convertJapNumbers } from '~/utils'
import { InputConfigItem } from '~/utils/types/input'

interface GripMobileInputItemProps {
  item: InputConfigItem
  inputKey: TestSubjectKey
  isNotMeasurable: Record<TestSubjectKey, boolean>
  measurable: boolean
  className?: string
}

const GripMobileInputItem: FC<GripMobileInputItemProps> = memo(
  ({ item, inputKey, isNotMeasurable, measurable, className }) => {
    const { t } = useTranslation()
    const leftOrRightTxt = item.name.includes('Left') ? t('左') : t('右')

    return (
      <Row
        className={`input-run-item my-1 ${className}`}
        style={{
          minWidth: 120,
        }}
      >
        <div className="mr-2 mt-1">{leftOrRightTxt}</div>

        <Form.Item
          name={item.name}
          rules={
            measurable && isNotMeasurable[inputKey] === true ? [] : item.rules
          }
          validateTrigger={
            measurable && isNotMeasurable[inputKey] === true
              ? []
              : ['onSubmit', 'onChange']
          }
        >
          <InputNumber
            className="border-primary"
            type="number"
            inputMode="decimal"
            step="any"
            disabled={isNotMeasurable[inputKey]}
            parser={(displayValue) => convertJapNumbers(displayValue as string)}
          />
        </Form.Item>

        <span className="p-2 text-base color-gray3">{t(item.suffix)}</span>
      </Row>
    )
  },
)

export default GripMobileInputItem
