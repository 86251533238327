import { Form, InputNumber } from 'antd'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getGripRules } from '~/components/inputs/test-input/utils/input'
import { checkIfSmallMobileScreen } from '~/student-utils/screen'

type Props = {
  item: string
  isUsingJpRuby: boolean
  className?: string
}

const TrainingGripInput = memo(({ item, isUsingJpRuby, className }: Props) => {
  const { t } = useTranslation()
  const leftOrRightTxt = item.includes('eft') ? (
    isUsingJpRuby ? (
      <ruby>
        左<rt className="font-normal text-xxs">ひだり</rt>
      </ruby>
    ) : (
      t('左')
    )
  ) : isUsingJpRuby ? (
    <ruby>
      右<rt className="font-normal text-xxs">みぎ</rt>
    </ruby>
  ) : (
    t('右')
  )

  const gripRules = useMemo(
    () => getGripRules(t, isUsingJpRuby),
    [t, isUsingJpRuby],
  )

  return (
    <div className={`flex m-2 ${className}`}>
      <div className="mr-2 mt-1">{leftOrRightTxt}</div>

      <Form.Item name={item} rules={gripRules}>
        <InputNumber
          style={checkIfSmallMobileScreen() ? { width: 58 } : undefined}
          className="border-primary tw-w-2"
          type="number"
          inputMode="decimal"
          onKeyDown={(e) => {
            if (e.key === '-' || e.key === '+') {
              e.preventDefault()
            }
          }}
        />
      </Form.Item>

      <div className="ml-2 mt-2">kg</div>
    </div>
  )
})

export default TrainingGripInput
