import type { QuestionnaireV3Result } from '@alpha/core'
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  List,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  message,
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import { useStudentQuestionnaireResultApiQuery } from '~/lib/hooks/hooks'
import type { UploadQuestionnaireDataRequestStudent } from '~/types/api/alpha/questions'
import isJapanese from '~/utils/isJapanese'
import {
  getCurrentPrefectureQuestions,
  getIsOptionalQuestions,
} from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import { StudentQuestionExtraText } from '~/utils/types'
import type { StudentMeSchool } from '~/utils/types/api/students'
import { Rt } from '../common/Rt'
import { WarnIcon } from '../icons/WarnIcon'
import CompletionLayout from '../layout/CompletionLayout'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'
import { formatDataQuestion } from './question/utils/format'
import { getFirstUnansweredQuestionKey } from './question/utils/questions'
import { AnswerType, StudentProcessingQuestion } from './question/utils/types'

const { Option } = Select

let isOptionalQuestions: boolean[]

/**
 * Path: /questionnaire
 */
const QuestionnairePage = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const [form] = Form.useForm()

  const [school, setSchool] = useState<StudentMeSchool | null>(null)
  const [questionData, setQuestionData] = useState<StudentProcessingQuestion[]>(
    [],
  )
  const [answeredQuestions, setAnsweredQuestions] =
    useState<QuestionnaireV3Result | null>(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(0)
  const [maxPage, setMaxPage] = useState(2)

  const [confirming, setConfirming] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [unansweredQuestionText, setUnansweredQuestionText] = useState<
    ReactElement | string | null
  >(null)
  const [isElementarySchool, setIsElementarySchool] = useState(false)
  const [listQuestion, setListQuestion] = useState<number[]>([])

  const { data: resultData } = useStudentQuestionnaireResultApiQuery()
  const { me: meApiData } = useAlphaStore()

  const isUsingJpRuby = isElementarySchool && isJapanese(i18n)
  const prefectureCode = meApiData?.school?.prefectureCode

  useEffect(() => {
    if (!resultData || !meApiData) return

    const _school = meApiData.school
    if (!_school) {
      console.error('Could not find school at meApiData:', meApiData)
      return
    }

    const _isElementarySchool = _school?.schoolCategoryCode === 'B1'
    const questionnaireV3 = resultData?.data?.survey
    const _answeredQuestions = questionnaireV3 ?? {}

    setAnsweredQuestions(_answeredQuestions)
    setIsElementarySchool(_isElementarySchool)

    const data = questionnaireV3 ?? {}

    if (data) {
      Object.keys(data).forEach((question) => {
        if (data[question] !== null && data[question] !== undefined)
          data[question] = `${data[question]}`
      })
    }

    form.setFieldsValue(data)

    setSchool(_school)

    const questionList = getCurrentPrefectureQuestions(
      prefectureCode as number,
      _school._id,
      _isElementarySchool,
    )

    setListQuestion(questionList)

    // reset the list
    isOptionalQuestions = getIsOptionalQuestions(questionList)
  }, [resultData, meApiData, form, prefectureCode])

  useEffect(() => {
    if (!school || listQuestion.length === 0) return

    /**
     * To update this prefecture question list.
     */
    const prefectureQuestionObject: Record<string, StudentProcessingQuestion> =
      {}

    for (const question of allQuestions) {
      if (listQuestion.includes(question.id)) {
        // question is modified later, so copy question information to new object
        prefectureQuestionObject[question.id] = _.cloneDeep(question)
      }
    }

    /**
     * To reorder questions
     */
    let prefectureQuestionDetails: StudentProcessingQuestion[] = []

    for (const allQuestionId of listQuestion) {
      prefectureQuestionDetails.push(prefectureQuestionObject[allQuestionId])
    }

    prefectureQuestionDetails = prefectureQuestionDetails.map(
      (value, index) => {
        value._id = value.id as number
        value.id = `q${index + 1}`
        const question = `Q${index + 1} : ${value.question}`
        if (!isElementarySchool) {
          const options = {}
          Object.keys(value.options).forEach((option) => {
            options[value.options[option]] = option
          })
          value.options = options
          value.question = question
          return value
        }

        if (prefectureCode === 8 && index === 7) {
          value.question =
            '1日にどのくらいテレビを見ますか（テレビゲームを含みます）'
          value.kanjiQuestion = [
            { kanji: '日', hina: 'にち' },
            { kanji: '見', hina: 'み' },
            { kanji: '含', hina: 'ふく' },
          ]
        }

        if (value.kanjiQuestion.length) {
          const questionKan = formatDataQuestion(
            value.question as string,
            value.kanjiQuestion,
          )

          value.question = (
            <>
              {`Q${index + 1} : `}
              {questionKan.map((value, index) => {
                if (value.type === 'kanji') {
                  return (
                    <ruby key={index} className="font-black">
                      {value.kanji}
                      <rt className="text-xxs font-normal">{value.hina}</rt>
                    </ruby>
                  )
                }

                // if (value.type === 'text') {
                return value.kanji
                // }
              })}
            </>
          )
        } else {
          value.question = `Q${index + 1} : ${value.question}`
        }

        if (value.kanjiOption) {
          const optionsKan = {}
          Object.keys(value.options).forEach((option) => {
            if (value.kanjiOption[value.options[option]].length) {
              const optionKan = formatDataQuestion(
                option,
                value.kanjiOption[value.options[option]],
              )
              optionsKan[value.options[option]] = (
                <>
                  {optionKan.map((value, index) => {
                    if (value.type === 'kanji') {
                      return (
                        <ruby key={index} className="font-normal">
                          {value.kanji}
                          <rt className="text-xxs font-normal">{value.hina}</rt>
                        </ruby>
                      )
                    }

                    // if (value.type === 'text') {
                    return (
                      <ruby key={index} className="font-normal">
                        {value.kanji}
                        <rt className="text-xxs text-gray-50">.</rt>
                      </ruby>
                    )
                    // }
                  })}
                </>
              )
            } else {
              optionsKan[value.options[option]] = <>{option}</>
            }
          })
          value.options = optionsKan
        }

        return value
      },
    )

    const _limit = 5
    setQuestionData(prefectureQuestionDetails)
    setMaxPage(Math.ceil(prefectureQuestionDetails.length / _limit))
    setSchool(school)
    setLimit(_limit)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQuestion, isElementarySchool, school])

  const handleSave = async () => {
    if (!school) {
      message.error(`${t('エラーが発生しました。')} School is not available!`)
      return
    }

    if (!meApiData) {
      message.error(
        `${t('エラーが発生しました。')} meApiData is not available!`,
      )
      return
    }

    setSubmitting(true)
    const questionnaire = {}

    for (const question of questionData) {
      if (
        question.answerType === AnswerType.multipleSelect &&
        answeredQuestions &&
        typeof answeredQuestions[question.id] !== 'string'
      ) {
        answeredQuestions[question.id] = answeredQuestions[question.id]
          .sort()
          .join(',')
      }
    }

    /**
     * @example
     * "q2"
     */
    const firstUnansweredQuestionKey = getFirstUnansweredQuestionKey(
      answeredQuestions as Record<string, number | string>,
      isOptionalQuestions,
    )

    if (firstUnansweredQuestionKey) {
      // required questions is not answered!
      setShowErrorModal(true)
      setSubmitting(false)
      setUnansweredQuestionText(
        questionData.find((q) => q.id === firstUnansweredQuestionKey)
          ?.question ?? '',
      )

      onChangeQuestionPage(firstUnansweredQuestionKey > 'q5' ? 2 : 1)
      return
    }

    for (const [key, value] of Object.entries(
      answeredQuestions as QuestionnaireV3Result,
    )) {
      questionnaire[key] = value
    }

    const students: UploadQuestionnaireDataRequestStudent[] = [
      {
        schoolGrade: meApiData.schoolGrade,
        schoolClass: meApiData.schoolClass,
        schoolAttendanceNumber: meApiData.schoolAttendanceNumber,
        questionnaire,
      },
    ]

    await axios
      .post(
        `${process.env.REACT_APP_REST_API_URL}/alpha/v1/student/upload-survey`,
        { students: JSON.stringify(students) },
      )
      .then((response) => {
        const { status, error } = response.data
        if (status === 200) {
          form.resetFields()
          setSubmitted(true)
          setTimeout(() => {
            history.push('/questionnaire_result')
          }, 2000)
        } else {
          console.error(
            `/alpha/v1/student/upload-survey - status is ${status} - error:`,
            error,
          )

          message.error(t('エラーが発生しました。'))
        }
        setSubmitting(false)
      })
      .catch((err: unknown) => {
        console.error('/alpha/v1/student/upload-survey - error:', err)
        message.error(t('エラーが発生しました。'))
      })
  }

  const renderQuestion = (_question: StudentProcessingQuestion) => {
    if (!school) {
      console.error('School is not available!')
      return
    }

    const isCustom =
      school?.prefectureCode === 9 || school?.prefectureCode === 45

    const _answered: number | string = answeredQuestions
      ? answeredQuestions[_question.id]
      : ''

    let answers: ReactElement | undefined

    if (
      (!_question.answerType || _question.answerType === AnswerType.options) &&
      _question.options
    ) {
      answers = (
        <Form.Item
          key={_question.id}
          name={_question.id}
          className="ant-form-item-custom"
        >
          {isCustom ? (
            <Radio.Group disabled={submitting}>
              <Row className="w-full option-question" gutter={8}>
                {Object.keys(_question.options).map((value, idx) => (
                  <Col key={idx}>
                    <Radio value={value}>{_question.options[value]}</Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          ) : (
            <Radio.Group disabled={submitting}>
              <Row className="w-full option-question">
                {Object.keys(_question.options).map((value, idx) => (
                  <Col key={idx} style={{ minWidth: '27%' }}>
                    <Radio value={value}>{_question.options[value]}</Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          )}
        </Form.Item>
      )
    }

    if (
      _question.answerType === AnswerType.multipleSelect &&
      _question.options
    ) {
      answers = (
        <Form.Item
          key={_question.id}
          name={_question.id}
          className="ant-form-item-custom"
        >
          <Checkbox.Group
            disabled={submitting}
            onChange={(values) => {
              setAnsweredQuestions({
                ...answeredQuestions,
                [_question.id]: values.sort().join(','),
              })
            }}
          >
            <Row className="w-full">
              {Object.keys(_question.options).map((value, idx) => (
                <Col key={idx} style={{ width: '33%' }}>
                  <Checkbox value={value}>{_question.options[value]}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      )
    }

    if (_question.answerType === AnswerType.number) {
      answers = (
        <Row className="w-full">
          <Form.Item
            key={_question.id}
            name={_question.id}
            className="ant-form-item-custom"
          >
            <InputNumber
              key={_question.id}
              size="middle"
              type="number"
              min={0}
              max={1000}
              step={1}
              inputMode="numeric"
              style={{ margin: 8 }}
            />
          </Form.Item>
          <label className="flex items-center">
            <ruby>
              分
              {isUsingJpRuby && <rt className="text-xxs font-normal">ふん</rt>}
            </ruby>
          </label>
        </Row>
      )
    }

    if (_question._id === 45) {
      const extraQuestion = getExtraQuestion(
        school.schoolName,
        _question,
      ) as Record<string, StudentQuestionExtraText>

      const options: { value: string; label: string }[] = []
      if (_question.options) {
        let defaultAnswer = ''
        Object.keys(extraQuestion).forEach((key) => {
          options.push({
            value: key,
            label: `${key}.${
              !isElementarySchool
                ? extraQuestion[key].name
                : extraQuestion[key].hiragana
            }`,
          })

          // eslint-disable-next-line eqeqeq
          if (key == _answered) {
            defaultAnswer = `${key}.${
              extraQuestion[key]
                ? extraQuestion[key][!isElementarySchool ? 'name' : 'hiragana']
                : ''
            }`
          }
        })

        answers = (
          <Form.Item name={_question.id} className="ant-form-item-custom">
            <Row className="w-full" gutter={8}>
              <Select
                value={defaultAnswer}
                onChange={(value) => {
                  setAnsweredQuestions({
                    ...answeredQuestions,
                    [_question.id]: value,
                  })
                  const dataQues = {}
                  dataQues[_question.id] = value
                  form.setFieldsValue(dataQues)
                }}
              >
                <>
                  {options.map((option) => (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </>
              </Select>
            </Row>
          </Form.Item>
        )
      }
    }

    const listWidth =
      school?.prefectureCode === 45
        ? 960
        : school?.prefectureCode === 9
          ? 855
          : 780

    return (
      <List.Item
        key={`question-${_question.id}`}
        style={{ width: listWidth, borderStyle: 'none' }}
      >
        <>
          <div className="font-black">{_question.question}</div>
          {answers}
        </>
      </List.Item>
    )
  }

  const renderConfirmQuestion = (_question: StudentProcessingQuestion) => {
    if (!school) {
      console.error('School is not available!')
      return null
    }

    const chosenAnswer = form.getFieldValue(_question.id)
    let answerConfirm =
      !_question.answerType ||
      [AnswerType.options, AnswerType.multipleSelect].includes(
        _question.answerType,
      )
        ? Array.isArray(_question.options)
          ? chosenAnswer
          : _question.answerType === AnswerType.multipleSelect
            ? String(chosenAnswer)
                .split(',')
                .map((a) => (
                  <>
                    <li>{_question?.options?.[a]}</li>
                  </>
                ))
            : _question?.options?.[chosenAnswer]
        : chosenAnswer

    if (_question._id === 45) {
      const extraQuestion = getExtraQuestion(school.schoolName, _question)

      answerConfirm = extraQuestion?.[chosenAnswer]
        ? extraQuestion[chosenAnswer][!isElementarySchool ? 'name' : 'hiragana']
        : ''
    }

    return (
      <List.Item
        key={`confirm-${_question.id}`}
        style={{
          borderStyle: 'none',
        }}
      >
        <>
          <div className="font-black leading-7">{_question.question}</div>
          {chosenAnswer !== undefined && chosenAnswer !== null ? (
            <span className="pl-10">
              {answerConfirm}
              {_question.answerUnit ?? ''}
            </span>
          ) : (
            <span className="pl-10" />
          )}
        </>
      </List.Item>
    )
  }

  const getPageData = () => {
    return questionData.slice((currentPage - 1) * limit, currentPage * limit)
  }

  const renderQuestionForm = () => {
    return (
      <Form
        form={form}
        onFinish={handleSave}
        layout="vertical"
        className="form-question"
        initialValues={{ ...answeredQuestions }}
      >
        <div className="w-full h-full flex flex-col items-center space-y-4 mt-10">
          <Row
            hidden={confirming}
            align="middle"
            justify="center"
            className={`form-question-content border-3 border-primary rounded-xl py-2 ${
              confirming ? 'hidden' : ''
            }`}
            style={{ width: 1000 }}
          >
            {school && (
              <List
                rowKey="id"
                className="flex flex-col items-center space-y-1"
                itemLayout="vertical"
                dataSource={getPageData()}
                renderItem={(question) => renderQuestion(question)}
                bordered={false}
                pagination={false}
              />
            )}
          </Row>

          <Row hidden={confirming} align="middle" justify="center">
            <Pagination
              current={currentPage}
              onChange={onChangeQuestionPage}
              total={questionData.length}
              pageSize={limit}
              size="default"
              className="ant-pagination-custom"
            />
          </Row>
          <Row
            hidden={!confirming}
            align="middle"
            justify="center"
            className="mb-2"
            style={{ width: 1000 }}
          >
            {isUsingJpRuby ? (
              <span className="text-lg font-black">
                <ruby>
                  回答<Rt className="text-tiny font-black">かいとう</Rt>
                </ruby>
                の
                <ruby>
                  確認<Rt className="text-tiny font-black">かくにん</Rt>
                </ruby>
              </span>
            ) : (
              <span className="text-lg font-black">{t('回答の確認')}</span>
            )}
          </Row>
          <Row
            hidden={!confirming}
            align="middle"
            justify="center"
            className={
              'form-question-content border-3 border-primary rounded-xl p-6 mx-16'
            }
          >
            {school && (
              <>
                <div className="show-pc">
                  <List
                    rowKey="id"
                    className="flex flex-col items-center space-y-1"
                    itemLayout="vertical"
                    grid={{ column: 2, gutter: 30 }}
                    dataSource={questionData}
                    renderItem={(question) => renderConfirmQuestion(question)}
                    bordered={false}
                    pagination={false}
                  />
                </div>

                <div className="show-sp">
                  <List
                    rowKey="id"
                    className="flex flex-col items-center space-y-1"
                    itemLayout="vertical"
                    grid={{ column: 1, gutter: 30 }}
                    dataSource={questionData}
                    renderItem={(question) => renderConfirmQuestion(question)}
                    bordered={false}
                    pagination={false}
                  />
                </div>
              </>
            )}
          </Row>
          {currentPage > 1 && (
            <Row align="middle" justify="center">
              <Button
                className="w-60"
                type="primary"
                size="large"
                onClick={() => onChangeQuestionPage(currentPage - 1)}
              >
                {t('もどる')}
              </Button>
            </Row>
          )}
          {currentPage > 0 && !confirming && (
            <Row align="middle" justify="center">
              <Button
                className="w-60 mb-2"
                type="primary"
                size="large"
                onClick={() => onChangeQuestionPage(currentPage + 1)}
              >
                {t('つぎへ')}
              </Button>
            </Row>
          )}
          {confirming && (
            <Row align="middle" justify="center">
              <Button
                className="w-60 mb-5"
                type="primary"
                size="large"
                // disabled={isFormInvalid}
                onClick={() => {
                  form.submit()
                }}
              >
                {isUsingJpRuby ? (
                  <ruby>
                    登録
                    <Rt className="text-tiny">とうろく</Rt>
                  </ruby>
                ) : (
                  t('登録')
                )}
              </Button>
            </Row>
          )}
        </div>
      </Form>
    )
  }

  const onChangeQuestionPage = (page: number) => {
    setAnsweredQuestions({ ...answeredQuestions, ...form.getFieldsValue() })
    // setFormInvalid(!!getFirstUnansweredQuestionKey(form.getFieldsValue()))

    setCurrentPage(page)
    setConfirming(page === maxPage + 1)
  }

  return (
    <Dashboard
      selectedMenu={TabIndex.Questionnaire}
      navbar={<div className="theme-nav-title">{t('アンケート')}</div>}
    >
      <div className={submitted ? 'hidden' : 'block'}>
        {renderQuestionForm()}
      </div>
      <div
        className={
          submitted ? 'flex h-full w-full justify-center pt-20' : 'hidden'
        }
      >
        <CompletionLayout
          message={
            isUsingJpRuby ? (
              <ruby className="text-2xl font-black">
                登録完了
                <Rt className="text-xxs">とうろくかんりょう</Rt>
              </ruby>
            ) : (
              t('登録完了')
            )
          }
        />
      </div>

      {/* Error modal */}
      <Modal
        visible={showErrorModal}
        onCancel={() => setShowErrorModal(false)}
        width={900}
        closeIcon={
          <CloseOutlined className="text-base" style={{ color: '#036EB8' }} />
        }
        footer={null}
        centered={true}
        bodyStyle={{ border: '3px solid #036EB8', borderRadius: 4 }}
      >
        <div
          className="p-10 flex flex-col justify-center items-center text-base font-black"
          style={{ color: '#B80303' }}
        >
          <div className="flex space-x-5">
            {WarnIcon}
            {isUsingJpRuby ? (
              <span>
                <ruby>
                  入力<Rt className="text-tiny">にゅうりょく</Rt>
                </ruby>
                されていない
                <ruby>
                  質問<Rt className="text-tiny">しつもん</Rt>
                </ruby>
                があるよ！
              </span>
            ) : (
              <span>{t('入力されていない質問があるよ！')}</span>
            )}
          </div>
          <span className="pl-10">{unansweredQuestionText}</span>
        </div>
      </Modal>
    </Dashboard>
  )
}

export default QuestionnairePage
