import { Form, InputNumber, Row } from 'antd'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TestSubjectKey } from '~/types/api/alpha/test-key'
import { convertJapNumbers } from '~/utils'
import { InputConfigItem } from '~/utils/types/input'

interface MobileInputItemProps {
  item: InputConfigItem
  inputKey: TestSubjectKey
  isNotMeasurable: Record<TestSubjectKey, boolean>
  measurable: boolean
}

const MobileInputItem: FC<MobileInputItemProps> = memo(
  ({ item, inputKey, isNotMeasurable, measurable }) => {
    const { t } = useTranslation()

    return (
      <div
        className="input-run-item"
        style={{
          display: 'inline-block',
          width: '50%',
          minWidth: 120,
        }}
      >
        <Row align="bottom">
          <Form.Item
            className="mb-0-f"
            name={item.name}
            label={
              item.label ? (
                <span className="text-10px">{item.label}</span>
              ) : null
            }
            rules={
              measurable && isNotMeasurable[inputKey] === true ? [] : item.rules
            }
            validateTrigger={
              measurable && isNotMeasurable[inputKey] === true
                ? []
                : ['onSubmit', 'onChange']
            }
          >
            <InputNumber
              className="border-primary"
              type="number"
              inputMode="decimal"
              step="any"
              disabled={isNotMeasurable[inputKey]}
              parser={(displayValue) =>
                convertJapNumbers(displayValue as string)
              }
            />
          </Form.Item>

          {item.suffix && (
            <div className="px-1">
              <span className="text-base color-gray3 h-4">
                {t(item.suffix)}
              </span>
            </div>
          )}
        </Row>
      </div>
    )
  },
)

export default MobileInputItem
