import { Form } from 'antd'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { LoadingSpin } from '~/components/loading-spin'
import { lastMonth, thisYear } from '~/utils/constants'
import { useMonthDaysChart } from '../hooks'
import { menuButton } from '../shared/utils'
import { ISportRes } from '../types'
import TrainingGripInput from './TrainingGripInput'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

/**
 * TODO: Remove dateTime, activatedTab input
 */
export const Grip = ({
  userId,
  dateTime,
  activatedTab,
  tabKey,
  isUsingJpRuby,
}) => {
  const {
    chartData: data,
    active,
    setActive,
    month,
    year,
    loading,
    sportsValue,
    testResultResponse,
    onClickLeftArrow,
    onClickRightArrow,
    disabled,
    setDisabled,
    submitSuccess,
    t,
    form,
    lineChart,
    onFinish,
    graphRecordLastestMonth,
    graphRecordLastestYear,
    graphRecordMaxMonth,
    graphRecordMaxYear,
  } = useMonthDaysChart(userId, tabKey, activatedTab, dateTime, 'gripStrength')

  const selectedMonth = month - 1
  const selectedYear = year

  const onValuesChange = (_a: unknown, total: ISportRes) => {
    if (
      (total.left1 !== null && total.left1 !== undefined) ||
      (total.left2 !== null && total.left2 !== undefined) ||
      (total.right1 !== null && total.right1 !== undefined) ||
      (total.right2 !== null && total.right2 !== undefined)
    ) {
      setDisabled(false)
    } else setDisabled(true)
  }

  const kgOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'average',
        yAlign: 'bottom',
        backgroundColor: '#fff',
        bodyColor: '#036EB8',
        borderColor: '#FFC833',
        borderWidth: 3,
        cornerRadius: 5,
        displayColors: false,
        caretPadding: 5,
        caretSize: 10,
        padding: 15,
        callbacks: {
          title: () => null,
          label: (context) => `${context.label} ${context.formattedValue}kg`,
        },
      },
    },
    scales: {
      y: {
        grace: testResultResponse.length ? '30%' : 0,
        max: testResultResponse.length ? null : 100,
        beginAtZero: true,
        grid: {
          borderDash: [1, 1],
          drawTicks: false,
          color: (context) => {
            if (context.tick.value === 0) {
              return 'rgba(0, 0, 0, 0)'
            }
            return 'rgba(0, 0, 0, 0.1)'
          },
        },
        position: 'left',
        ticks: {
          color: '#C4C4C4',
          padding: 8,
          font: {
            size: 8,
            weight: 400,
          },
        },
      },
      x: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 20,
          color: '#C4C4C4',
          font: {
            size: 8,
            weight: 400,
          },
        },
      },
    },
  }

  return (
    <div>
      <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
        {loading ? (
          <LoadingSpin />
        ) : (
          <div className="trainingWrapper">
            <div className="inputContainer">
              <div className="inputHeader">
                {isUsingJpRuby ? (
                  <span>
                    <ruby>
                      <span>測定</span>
                      <rt className="font-normal text-xxs">そくてい</rt>
                    </ruby>
                    を
                    <ruby>
                      <span>記録</span>
                      <rt className="font-normal text-xxs">きろく</rt>
                    </ruby>
                    する
                  </span>
                ) : (
                  t('測定を記録する')
                )}
              </div>
              {submitSuccess && (
                <div className="inputStatusButton">
                  <span>{t('登録完了')}</span>
                </div>
              )}
              <div className="inputBody">
                <div className="flex justify-between">
                  <div className="flex flex-col relative p-2 ml-6 my-3 mr-2 sp:m-3 w-1/2 border-1px border-solid border-color-blue-lighter rounded-lg">
                    <div className="absolute -top-2 -left-1 px-2 bg-white font-bold">
                      {t('1回目')}
                    </div>

                    <TrainingGripInput
                      item={'right1'}
                      className="mt-2"
                      isUsingJpRuby={isUsingJpRuby}
                    />
                    <TrainingGripInput
                      item={'left1'}
                      isUsingJpRuby={isUsingJpRuby}
                    />
                  </div>

                  <div className="flex flex-col relative p-2 ml-2 my-3 mr-6 sp:m-3 w-1/2 border-1px border-solid border-color-blue-lighter rounded-lg">
                    <div className="absolute -top-2 -left-1 px-2 bg-white font-bold">
                      {t('2回目')}
                    </div>

                    <TrainingGripInput
                      item={'right2'}
                      className="mt-2"
                      isUsingJpRuby={isUsingJpRuby}
                    />
                    <TrainingGripInput
                      item={'left2'}
                      isUsingJpRuby={isUsingJpRuby}
                    />
                  </div>
                </div>
              </div>

              <div className="inputFooter">
                <Form.Item>
                  <button type="submit" disabled={disabled}>
                    {sportsValue?.studentID ? (
                      isUsingJpRuby ? (
                        <ruby>
                          修正
                          <rt className="text-tiny">しゅうせい</rt>
                        </ruby>
                      ) : (
                        t('修正')
                      )
                    ) : isUsingJpRuby ? (
                      <ruby>
                        登録
                        <rt className="text-tiny">とうろく</rt>
                      </ruby>
                    ) : (
                      t('登録')
                    )}
                  </button>
                </Form.Item>
              </div>
            </div>

            <div className="chartContainer">
              <div className="chartHeader">{t('グラフ')}</div>
              <div className="chartDescription">
                <div className="chartLeftContent">
                  {menuButton.map((button, idx: number) => (
                    <div
                      className="switchButton"
                      key={idx}
                      style={idx === active ? { background: '#fff' } : {}}
                      onClick={() => setActive(idx)}
                      role="presentation"
                    >
                      <span>{t(button)}</span>
                    </div>
                  ))}
                </div>
                <div className="chartRightContent">
                  <div className="headerContent">
                    <span>
                      {isUsingJpRuby ? (
                        <ruby>
                          <span>最新記録</span>
                          <rt className="font-normal text-xxs">
                            さいしんきろく
                          </rt>
                        </ruby>
                      ) : (
                        t('最新記録')
                      )}
                    </span>
                    <span>
                      {isUsingJpRuby ? (
                        <ruby>
                          <span>最高記録</span>
                          <rt className="font-normal text-xxs">
                            さいこうきろく
                          </rt>
                        </ruby>
                      ) : (
                        t('最高記録')
                      )}
                    </span>
                  </div>
                  <div className="bodyContent">
                    <span>{`${
                      active === 0
                        ? graphRecordLastestMonth
                        : graphRecordLastestYear
                    }kg`}</span>
                    <span>{`${
                      active === 0 ? graphRecordMaxMonth : graphRecordMaxYear
                    }kg`}</span>
                  </div>
                </div>
              </div>

              <div className="lineChart">
                <Line
                  ref={lineChart as any}
                  height="300px"
                  data={data}
                  options={kgOptions as any}
                />
                {(active === 0
                  ? selectedMonth >= lastMonth
                  : selectedYear > thisYear) && (
                  <div
                    onClick={onClickLeftArrow}
                    className="leftArrow"
                    role="presentation"
                  >
                    <img
                      className="imageChangeDate"
                      width={12}
                      height={12}
                      src="assets/images/prev-icon.svg"
                      alt=""
                    />
                  </div>
                )}
                {(active === 0
                  ? selectedMonth < lastMonth
                  : selectedYear < thisYear) && (
                  <div
                    id="rightClickArrow"
                    onClick={onClickRightArrow}
                    className="rightArrow"
                    role="presentation"
                  >
                    <img
                      className="imageChangeDate"
                      width={12}
                      height={12}
                      src="assets/images/next-icon.svg"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="listContainer">
              <div className="listHeader">
                <span>
                  <ruby>
                    {t('測定日')}
                    {isUsingJpRuby ? <rt>そくていび</rt> : null}
                  </ruby>
                </span>
                <span>
                  <ruby>
                    {t('記録')}
                    {isUsingJpRuby ? <rt>記録</rt> : null}
                  </ruby>
                </span>
              </div>
              {testResultResponse.map((data, index: number) => (
                <div key={index} className="listContent">
                  <span>{moment(data.date).format('M/D')}</span>
                  <span>{`${data.value}kg`}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}
