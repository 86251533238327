import { Form, InputNumber } from 'antd'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TestSubjectKey } from '~/types/api/alpha/test-key'
import { convertJapNumbers } from '~/utils'
import { InputConfigItem } from '~/utils/types/input'

type Props = {
  item: InputConfigItem
  inputKey: TestSubjectKey
  isNotMeasurable: Record<TestSubjectKey, boolean>
  className?: string
}

const GripInputItem: FC<Props> = memo(
  ({ item, inputKey, isNotMeasurable, className }) => {
    const { t } = useTranslation()
    const leftOrRightTxt = item.name.includes('Left') ? t('左') : t('右')

    return (
      <div className={`flex text-12 ${className}`}>
        <div className="mr-2 mt-1">{leftOrRightTxt}</div>

        <Form.Item
          name={item.name}
          rules={isNotMeasurable[inputKey] === true ? [] : item.rules}
          validateTrigger={
            isNotMeasurable[inputKey] === true ? [] : ['onSubmit', 'onChange']
          }
        >
          <InputNumber
            className="border-primary"
            type="number"
            inputMode="decimal"
            step="any"
            disabled={isNotMeasurable[inputKey]}
            parser={(displayValue) => {
              return convertJapNumbers(displayValue as string)
            }}
          />
        </Form.Item>

        <div className="mt-3 pl-1">{t(item.suffix)}</div>
      </div>
    )
  },
)

export default GripInputItem
