import { QuestionCircleOutlined } from '@ant-design/icons'
import { Space, Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import React, { memo } from 'react'

const tooltipOverLayInnerStyle: React.CSSProperties = {
  fontSize: 13,
  color: 'black',
  backgroundColor: '#fafafa',
  width: 550,
  borderRadius: 25,
  borderColor: '#036eb8',
  borderWidth: 1,
  display: 'flex',
  flexDirection: 'column',
}

const QuestionTooltip = memo(
  ({
    TitleChild,
    placement,
    innerOverlayCss,
    children
  }: {
    TitleChild: React.ReactNode,
    children?: React.ReactNode,
    placement?: TooltipPlacement,
    innerOverlayCss?: React.CSSProperties
  }) => {
    return (
      <Space wrap className="ml-2">
        <Tooltip
          color="#fafafa"
          overlayInnerStyle={{
            ...tooltipOverLayInnerStyle,
            ...innerOverlayCss
          }}
          title={TitleChild}
          placement={placement ?? "rightTop"}
        >
          <div className="flex justify-items-center">
            <QuestionCircleOutlined
              className="text-xl stroke-current mb-0.5"
              style={{
                strokeWidth: '13',
                fontSize: 16
              }}
            />
            
            {children && <div className='pl-2'>
              {children}
            </div>}
          </div>
        </Tooltip>
      </Space>
    )
  },
)

export default QuestionTooltip
