import axios from 'axios'

export const API_URL = process.env.REACT_APP_REST_API_URL

const defaultOptions = {}

function getApi<T = any>(
  path: string,
  options: { headers?: {} } = {},
  apiURL?: string,
) {
  return axios.get<T>(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function postApi<T = any>(
  path: string,
  data?: {},
  options: { headers?: {} } = {},
) {
  return axios.post<T>(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function putApi<T = any>(
  path: string,
  data?: {},
  options: { headers?: {} } = {},
) {
  return axios.put<T>(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function patchApi<T = any>(
  path: string,
  data?: {},
  options: { headers?: {} } = {},
) {
  return axios.patch<T>(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function deleteApi<T = any>(path: any, options: any = {}) {
  return axios.delete<T>(`${API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

const Api = {
  get: getApi,
  post: postApi,
  put: putApi,
  delete: deleteApi,
  patch: patchApi,
}

export default Api
