export const videosBaseUrl =
  'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/'

export const exerciseProgramVideosBaseUrlLength = videosBaseUrl.length
export const videosBaseUrlLowQuality =
  'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/example_videos_low_quality/'

export const getLowUrl = (url: string) => {
  const videoId = url.slice(
    exerciseProgramVideosBaseUrlLength,
    exerciseProgramVideosBaseUrlLength + 3,
  )

  return `${videosBaseUrlLowQuality}${videoId}.mp4`
}
