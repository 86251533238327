import { gradeNames } from '~/components/shared/constants'
import {
  IOldTestResult,
  IOldTestResultWithTitle,
  ISchoolBaseInfo,
} from '~/types/api/alpha/student/test-result'
import { SchoolCategoryCode } from '~/utils/constants'

export const findClass = (
  index: number,
): { schoolCategory: SchoolCategoryCode; schoolGrade: number } => {
  if (index >= 0 && index < 6) {
    return {
      schoolCategory: 'B1',
      schoolGrade: index + 1,
    }
  }
  if (index >= 6 && index < 9) {
    return {
      schoolCategory: 'C1',
      schoolGrade: index - 5,
    }
  }
  return {
    schoolCategory: 'D1',
    schoolGrade: index - 8,
  }
}

/**
 * Return grade index.
 * @param category - school category
 * @param grade - grade
 * @returns - from 0 to 11 equals to 1 to 12 grade
 */
export const findGradeCommonIndex = (category: string, grade: number) => {
  if (category === 'B1') {
    // 0 -> 5
    return grade - 1
  }

  if (category === 'C1') {
    // 6 -> 8
    return 5 + grade
  }

  // 9 -> 11
  return 8 + grade
}

/**
 * Find grade index
 */
export const findGradeIndex = (data: ISchoolBaseInfo) => {
  if (!data?.schoolGrade) return -1

  const category = data.schoolCategory
  const grade = parseFloat(data.schoolGrade as unknown as string)

  if (category) {
    return findGradeCommonIndex(category, grade)
  }

  return -1
}

/**
 * Find grade index
 */
export const findGradeIndexForOld = (data: {
  schoolCategory: SchoolCategoryCode
  schoolGrade: number
}) => {
  if (!data.schoolGrade || !data.schoolCategory) return -1
  return findGradeCommonIndex(data.schoolCategory, data.schoolGrade)
}

export const finOldResByIndex = (
  dataBefore: IOldTestResult[],
  dataNow: ISchoolBaseInfo,
  testYear: number,
  index: number,
): IOldTestResultWithTitle => {
  let defaultData = {} as IOldTestResultWithTitle
  if (index >= 0 && index < 12) {
    const currentClassIndex = findGradeIndex(dataNow)
    const { schoolCategory, schoolGrade } = findClass(index)
    const year = testYear - currentClassIndex + index
    const title = gradeNames[index] || ''

    if (dataBefore?.length > 0) {
      for (let i = 0; i < dataBefore.length; i++) {
        const item = dataBefore[i]

        if (
          item &&
          item.schoolCategory === schoolCategory &&
          item.schoolGrade?.toString() === schoolGrade?.toString()
        ) {
          defaultData = item
          break
        }
      }
    }

    return {
      // @ts-ignore
      sizeTest: {},
      ...defaultData,
      schoolCategory,
      schoolGrade,
      title,
      testYear: year,
    }
  }
  return defaultData
}
