import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TestSubjectKey } from '~/types/api/alpha/test-key'
import { ITestValResult } from '~/utils/types/api/alpha/test-result'
import { InputConfigItem } from '~/utils/types/input'
import GripMobileInputItem from './GripMobileInputItem'
import { InputConfig } from './utils/input'

interface MobileGripInputGroupProps {
  input: InputConfig
  lastYearStudent: ITestValResult
  isNotMeasurable: Record<TestSubjectKey, boolean>
}

const GripMobileInputGroup: FC<MobileGripInputGroupProps> = memo(
  ({ input, lastYearStudent, isNotMeasurable }) => {
    const { t } = useTranslation()
    const inputItems = input.items

    return (
      <div className="flex justify-center mt-3 mb-10">
        <div>
          <div className="flex flex-col relative p-2 m-4 border-1px border-solid border-color-blue-lighter rounded-lg">
            <div className="absolute -top-2 -left-1 px-2 bg-white font-bold">
              {t('1回目')}
            </div>

            <GripMobileInputItem
              key={inputItems[0].name}
              item={inputItems[0] as InputConfigItem}
              inputKey={input.key}
              isNotMeasurable={isNotMeasurable}
              measurable={input.measurable}
              className="mt-4"
            />

            <GripMobileInputItem
              key={inputItems[2].name}
              item={inputItems[2] as InputConfigItem}
              inputKey={input.key}
              isNotMeasurable={isNotMeasurable}
              measurable={input.measurable}
            />
          </div>

          <div className="flex flex-col relative p-2 m-4 border-1px border-solid border-color-blue-lighter rounded-lg">
            <div className="absolute -top-2 -left-1 px-2 bg-white font-bold">
              {t('2回目')}
            </div>

            <GripMobileInputItem
              key={inputItems[1].name}
              item={inputItems[1] as InputConfigItem}
              inputKey={input.key}
              isNotMeasurable={isNotMeasurable}
              measurable={input.measurable}
              className="mt-4"
            />

            <GripMobileInputItem
              key={inputItems[3].name}
              item={inputItems[3] as InputConfigItem}
              inputKey={input.key}
              isNotMeasurable={isNotMeasurable}
              measurable={input.measurable}
            />
          </div>
        </div>

        <div className="flex items-center pl-8">
          <span className="mr-1 text-base">
            {`${lastYearStudent[input.key]?.value ?? '-'}`}
          </span>
          <span className="text-base color-gray3">
            {t(input.suffix as string)}
          </span>
        </div>
      </div>
    )
  },
)

export default GripMobileInputGroup
