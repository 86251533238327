import { Button, Form, Select, message } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { isLowHeightScreen } from '~/student-utils/screen'
import {
  daySelections,
  getListData,
  monthSelections,
  yearSelections,
} from '~/student-utils/string'
import Api from '~/utils/api'
import isJapanese from '~/utils/isJapanese'
import Dropdown from '../common/Dropdown'
import { Rt } from '../common/Rt'
import { Dashboard } from '../layout/Dashboard'
import { LoadingSpin } from '../loading-spin'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'

const MyPage = () => {
  const { t, i18n } = useTranslation()
  const auth = useContext(AuthContext)
  const { me } = useAlphaStore()

  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp
  const history = useHistory()

  const schoolName = me?.schoolName

  const [form] = Form.useForm()

  const [listDay, setListDay] = useState(daySelections)
  const [loading, setLoading] = useState<boolean>(false)

  const onFinish = async () => {
    const submitData = form.getFieldsValue()

    if (
      !submitData.yearBirth ||
      !submitData.monthBirth ||
      !submitData.dayBirth
    ) {
      message.error(
        t('無効な生年月日が含まれています。確認し、再度登録してください。'),
        8,
      )
      return
    }

    submitData.yearBirth = Number(submitData.yearBirth)
    submitData.monthBirth = Number(submitData.monthBirth)
    submitData.dayBirth = Number(submitData.dayBirth)

    setLoading(true)

    const apiUrl = '/alpha/v1/student/me'
    try {
      await Api.patch(apiUrl, submitData)
      message.success(t('編集完了'))
    } catch (err) {
      console.error(err)
      message.error(t('エラーが発生しました。'))
    }
    setLoading(false)
  }
  const setData = () => {
    form.resetFields()
    form.setFieldsValue({
      yearBirth: me?.yearBirth,
      monthBirth: me?.monthBirth,
      dayBirth: me?.dayBirth,
    })
  }

  const handleChange = () => {
    const data = form.getFieldsValue()
    if (!data) return

    if (!(data.yearBirth && data.monthBirth)) {
      return
    }

    const date = new Date(data.yearBirth, data.monthBirth, 0).getDate()
    setListDay(getListData(1, date))

    if (data.dayBirth && data.dayBirth > date) {
      form.setFieldsValue({
        yearBirth: data.yearBirth,
        monthBirth: data.monthBirth,
        dayBirth: '',
      })
    }
  }

  useEffect(() => {
    if (me) {
      setData()
      handleChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  return (
    <Dashboard
      selectedMenu={TabIndex.Account}
      navbar={<div className="theme-nav-title">{t('マイページ')}</div>}
    >
      {loading ? (
        <div className="mt-12 p-3 flex justify-center items-center overflow-hidden">
          <LoadingSpin />
        </div>
      ) : (
        <>
          <div className="show-pc mt-12 p-3 overflow-hidden">
            <div className="flex justify-center items-center">
              <div className="bg-gray-150 pt-11 pb-5 rounded-10px modal-mypage overflow-hidden">
                <div className="space-y-6">
                  <div className="space-y-7 px-40 pr-20">
                    <div className="flex">
                      <div className="flex-1 text-left">
                        {isUsingJpRuby ? (
                          <ruby className="font-black">
                            名前
                            <Rt className="text-xxs font-black">なまえ</Rt>
                          </ruby>
                        ) : (
                          <ruby className="font-black">{t('名前')}</ruby>
                        )}
                      </div>
                      <div className="text-lg text-center">
                        {me?.familyName && (
                          <div>
                            {t('フルネーム', {
                              familyName: me?.familyName,
                              givenName: me?.givenName,
                            })}
                          </div>
                        )}
                      </div>
                      <div className="flex-1" />
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-left">
                        {isUsingJpRuby ? (
                          <ruby className="font-black">
                            学校名
                            <Rt className="text-xxs font-black">
                              がっこうめい
                            </Rt>
                          </ruby>
                        ) : (
                          <ruby className="font-black">{t('学校名')}</ruby>
                        )}
                      </div>
                      <div className="text-lg text-center">{schoolName}</div>
                      <div className="flex-1" />
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-left">
                        {isUsingJpRuby ? (
                          <ruby className="font-black mr-5">
                            学年
                            <Rt className="text-xxs font-black">がくねん</Rt>
                          </ruby>
                        ) : (
                          <ruby className="font-black mr-5">{t('学年')}</ruby>
                        )}
                        <span className="text-lg">{me?.schoolGrade}</span>
                      </div>
                      <div className="flex-1 text-left">
                        <span className="font-black mr-5">{t('クラス')}</span>
                        <span className="text-lg">{me?.schoolClass}</span>
                      </div>
                      <div className="flex-1 text-left">
                        {isUsingJpRuby ? (
                          <ruby className="font-black mr-5">
                            出席番号
                            <Rt className="text-xxs font-black">
                              しゅっせきばんごう
                            </Rt>
                          </ruby>
                        ) : (
                          <ruby className="font-black mr-5">
                            {t('出席番号')}
                          </ruby>
                        )}
                        <span className="text-lg">
                          {me?.schoolAttendanceNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="inputBody px-60">
                    <Form
                      form={form}
                      initialValues={{
                        yearBirth: '',
                        monthBirth: '',
                        dayBirth: '',
                      }}
                    >
                      <div className="flex align-center item-end justify-center">
                        <div className="relative">
                          <span className="inputDateLabel">
                            {t('生年月日')}
                          </span>
                          <Form.Item name="yearBirth">
                            <Select
                              style={{ width: 120 }}
                              onChange={handleChange}
                              options={yearSelections}
                            />
                          </Form.Item>
                        </div>

                        <span className="mx-2 textDate">
                          {isUsingJpRuby ? (
                            <ruby>
                              年<rt>ねん</rt>
                            </ruby>
                          ) : (
                            t('年_')
                          )}
                        </span>
                        <Form.Item name="monthBirth">
                          <Select
                            style={{ width: 80 }}
                            onChange={handleChange}
                            options={monthSelections}
                          />
                        </Form.Item>
                        <span className="mx-2 textDate">
                          {isUsingJpRuby ? (
                            <ruby>
                              月<rt>がつ</rt>
                            </ruby>
                          ) : (
                            t('月_')
                          )}
                        </span>
                        <Form.Item name="dayBirth">
                          <Select
                            style={{ width: 80 }}
                            onChange={handleChange}
                            options={listDay}
                          />
                        </Form.Item>
                        <span className="mx-2 textDate">
                          {isUsingJpRuby ? (
                            <ruby>
                              日<rt>にち</rt>
                            </ruby>
                          ) : (
                            t('日_')
                          )}
                        </span>
                      </div>
                    </Form>
                  </div>
                  <div>
                    <div className="flex justify-between px-20">
                      <div className="space-x-4 flex items-center justify-center ml-auto mr-2">
                        <div>
                          <div>
                            <Button
                              type="primary"
                              className="w-60"
                              size="large"
                              onClick={() => history.push('/logout')}
                            >
                              {t('ログアウト')}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="space-x-4 flex items-center justify-center mr-auto ml-2">
                        <div>
                          <div>
                            <Button
                              type="primary"
                              className="w-60"
                              size="large"
                              onClick={onFinish}
                            >
                              {isUsingJpRuby ? (
                                <ruby>
                                  修正
                                  <rt>しゅうせい</rt>
                                </ruby>
                              ) : (
                                t('修正')
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`show-sp h-screen fixed p-3 ${
              isLowHeightScreen ? ' mt-4' : ' mt-12'
            }`}
          >
            <div className="space-y-6 px-3 bg-gray-150 pt-11 pb-5 rounded-10px modal-mypage">
              <div className="space-y-7">
                <div className="flex">
                  <div className="flex-1 text-left" style={{ minWidth: 40 }}>
                    {isUsingJpRuby ? (
                      <ruby className="font-black">
                        名前
                        <Rt className="text-xxs font-black">なまえ</Rt>
                      </ruby>
                    ) : (
                      <ruby className="font-black">{t('名前')}</ruby>
                    )}
                  </div>

                  <div className="text-lg text-center w-full">
                    {me && (
                      <div>
                        {t('フルネーム', {
                          familyName: me.familyName,
                          givenName: me.givenName,
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div
                    className="text-left"
                    style={{ minWidth: isUsingJp ? 49 : 100 }}
                  >
                    {isUsingJpRuby ? (
                      <ruby className="font-black">
                        学校名
                        <Rt className="text-xxs font-black">がっこうめい</Rt>
                      </ruby>
                    ) : (
                      <ruby className="font-black">{t('学校名')}</ruby>
                    )}
                  </div>

                  <div className="text-lg text-center w-full">{schoolName}</div>
                </div>
                <div className="flex">
                  <div className="flex-1 text-left">
                    {isUsingJpRuby ? (
                      <ruby className="font-black mr-5">
                        学年
                        <Rt className="text-xxs font-black">がくねん</Rt>
                      </ruby>
                    ) : (
                      <ruby className="font-black mr-5">{t('学年')}</ruby>
                    )}
                    <span className="text-lg">{me?.schoolGrade}</span>
                  </div>
                  <div className="flex-1 text-left">
                    <span className="font-black mr-5">{t('クラス')}</span>
                    <span className="text-lg">{me?.schoolClass}</span>
                  </div>
                  <div className="flex-1 text-left">
                    {isUsingJpRuby ? (
                      <ruby className="font-black mr-5">
                        出席番号
                        <Rt className="text-xxs font-black">
                          しゅっせきばんごう
                        </Rt>
                      </ruby>
                    ) : (
                      <ruby className="font-black mr-5">{t('出席番号')}</ruby>
                    )}
                    <span className="text-lg">
                      {me?.schoolAttendanceNumber}
                    </span>
                  </div>
                </div>
              </div>

              <div className="inputBody">
                <Form
                  form={form}
                  initialValues={{
                    yearBirth: '',
                    monthBirth: '',
                    dayBirth: '',
                  }}
                >
                  <div className="flex align-center item-end justify-between">
                    <div className="relative">
                      <span className="inputDateLabel">{t('生年月日')}</span>
                      <Form.Item name="yearBirth">
                        {/* <Select
                          style={{ width: 100 }}
                          onChange={handleChange}
                          options={yearSelections}
                        /> */}
                        <Dropdown
                          onChange={handleChange}
                          options={yearSelections}
                        />
                      </Form.Item>
                    </div>

                    <span className="mx-2 my-1 textDate">{t('年_')}</span>
                    <Form.Item name="monthBirth">
                      {/* <Select
                        style={{ width: 60 }}
                        onChange={handleChange}
                        options={monthSelections}
                      /> */}
                      <Dropdown
                        onChange={handleChange}
                        options={monthSelections}
                        className={'w-20'}
                      />
                    </Form.Item>
                    <span className="mx-2 my-1 textDate">{t('月_')}</span>
                    <Form.Item name="dayBirth">
                      {/* <Select
                        style={{ width: 60 }}
                        onChange={handleChange}
                        options={listDay}
                      /> */}

                      <Dropdown
                        onChange={handleChange}
                        options={listDay}
                        className={'w-20'}
                        value={form.getFieldValue('dayBirth')}
                      />
                    </Form.Item>
                    <span className="mx-2 my-1 textDate">{t('日_')}</span>
                  </div>
                </Form>
              </div>

              <div>
                <div className="flex justify-between">
                  <div
                    className="space-x-4 flex items-center justify-center"
                    style={{ margin: 0 }}
                  >
                    <Button
                      type="primary"
                      className="w-30"
                      size="large"
                      onClick={() => history.push('/logout')}
                    >
                      {t('ログアウト')}
                    </Button>
                  </div>
                  <div
                    className="space-x-4 flex items-center justify-center"
                    style={{ margin: 0 }}
                  >
                    <Button
                      type="primary"
                      className="w-30"
                      size="large"
                      onClick={onFinish}
                    >
                      {isUsingJpRuby ? (
                        <ruby>
                          修正
                          <rt>しゅうせい</rt>
                        </ruby>
                      ) : (
                        t('修正')
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  )
}

export default MyPage
