import { FC, LegacyRef, useEffect, useRef } from 'react'
import { useVideojs } from 'react-videojs-hook'

import { useAnalyticsEventMutation } from '@alpha/core'

import axios from 'axios'
import 'video.js/dist/video-js.css'
import { VIDEO_TYPE, thisMonth, thisYear } from '~/utils/constants'
import ja from './videojs/languages/ja.json'

type Props = {
  keyName: string
  videoType: VIDEO_TYPE
  src: string
  thumb: string
  size: {
    height?: number
    width?: number
  }
  className?: string
  onThumbnailLoaded?: () => void
  autoPlay?: boolean
  isAutoPlayManually?: boolean
}

const Video: FC<Props> = ({
  src,
  size,
  thumb,
  className,
  keyName,
  videoType,
  onThumbnailLoaded,
  autoPlay,
  isAutoPlayManually,
}) => {
  const [submitEvent] = useAnalyticsEventMutation()
  const startPlayingRef = useRef(false)
  const vjsWrapperRef = useRef<HTMLDivElement | undefined>()

  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src,
    poster: thumb,
    className,
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    language: 'ja',
    languages: { ja },
    controls: true,
    autoplay: autoPlay,
    responsive: true,
    onReady: async () => {
      const vjsPoster =
        vjsWrapperRef.current!.getElementsByClassName('vjs-poster')[0]
      const imgVjsPoster = vjsPoster.getElementsByTagName('img')[0]
      if (imgVjsPoster.complete) onThumbnailLoaded?.()
      imgVjsPoster.addEventListener('load', () => {
        onThumbnailLoaded?.()
      })

      if (isAutoPlayManually) {
        const ele = vjsRef.current as (Element & { play: () => void }) | null

        if (ele) {
          ele.play()
        }
      }
    },
    onPlay: async (currentTime) => {
      if (!startPlayingRef.current) {
        startPlayingRef.current = true
        await axios.post(
          `${process.env.REACT_APP_REST_API_URL}/alpha/v1/student/video-view-count`,
          {
            keyName,
            type: videoType,
          },
        )
        // console.log(`Start Playing: ${keyName}`)
      }

      const now = new Date()

      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onPlay',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: now.getTime(),
            date: now,
            year: thisYear,
            month: thisMonth,
            day: now.getDate(),
          }),
        },
      })
      // console.log('Video played at:', currentTime)
    },
    onPause: async (currentTime) => {
      const now = new Date()

      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onPause',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: now.getTime(),
            date: now,
            year: thisYear,
            month: thisMonth,
            day: now.getDate(),
          }),
        },
      })
      // console.log('Video paused at:', currentTime)
    },
    onEnd: async (currentTime) => {
      const now = new Date()

      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onEnd',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: now.getTime(),
            date: now,
            year: thisYear,
            month: thisMonth,
            day: now.getDate(),
          }),
        },
      })
      // console.log('Video ended at:', currentTime)
    },
    // onTimeUpdate: (currentTime) => {
    //   console.log('Video current time is:', currentTime)
    // },
  })

  useEffect(() => {
    const ele = vjsRef.current as (Element & { play: () => void }) | null

    if (ele) {
      ele.addEventListener(
        'contextmenu',
        (e: Event) => {
          e.preventDefault()
        },
        false,
      )
    }
  }, [vjsRef])

  return (
    <div data-vjs-player ref={vjsWrapperRef as LegacyRef<HTMLDivElement>}>
      {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
      <video
        ref={vjsRef}
        id={vjsId}
        preload="none"
        className={vjsClassName}
        width={size.width}
        height={size.height}
      />
    </div>
  )
}

export default Video
