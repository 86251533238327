import { GoogleOAuthProvider } from '@react-oauth/google'
import React, { ErrorInfo } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { install } from 'resize-observer'

// WARNING: This order is important! index.css -> antd.css
import './index.css'
// WARNING: This order is important!
import './antd.css'

import { ConfigProvider } from 'antd'
import jaJP from 'antd/lib/locale/ja_JP'

import moment from 'moment'

// import i18n (needs to be bundled ;))
import './i18n'

import { MsalProvider } from '@azure/msal-react'
import App from './App'
import ErrorFallback from './components/cards/ErrorFallback'
import { msAlInstance, REACT_APP_GOOGLE_CLIENT_ID } from './student-utils/const'
import { createDraggableMarker } from './student-utils/drag'
import { initializeInterceptors } from './student-utils/interceptors'
import { reportErrorSlack } from './utils/reportError'

// Support for iOS 13.0 devices (releases from 2019), may be removed in the future after 10 years!
// This is required for ChartComponent at /training page.
if (!window.ResizeObserver) install()

initializeInterceptors()

moment.locale('ja')

if (window.innerWidth <= 540) {
  // mobile ver
  window.document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      createDraggableMarker()
    }, 1600)
  })
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
      <MsalProvider instance={msAlInstance}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(error: Error, info: ErrorInfo) => {
            reportErrorSlack(error, info)
          }}
        >
          <ConfigProvider locale={jaJP}>
            <App />
            {/* <MaintainNoticePage /> */}
          </ConfigProvider>
        </ErrorBoundary>
      </MsalProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
